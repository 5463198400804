import React from "react";
import MainLayout from "../components/layoutmain";
import { StaticImage } from "gatsby-plugin-image";

export default function Home() {
  const seoProps = {
    title: "Numeko - Nos expertises",
    description:
      "Conseil & gestion de projet, Graphisme (Design), Applicatif: Web, Mobile, Métier, API, devOps. Des spécialistes qualifiés pour vous accompagner dans la conception de projets web & mobiles performants et durables.",
  };

  return (
    <MainLayout seoProps={seoProps}>
      <div className={"bloc-main expertises"}>
        <div className={"bloc-category bloc-presentation"}>
          <h1>NOS EXPERTISES</h1>
          <hr />
          <h4>
            Des spécialistes qualifiés pour vous accompagner dans la conception
            de projets web & mobile <span>performants</span> et{" "}
            <span>durables</span>.
          </h4>
        </div>

        <div className={"bloc-category bloc-content"}>
          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>01</div>
              <h1>Conseil & Gestion de projet</h1>
              <div className={"content"}>
                Nous vous accompagnons dans :
                <ul>
                  <li>L’expression de vos besoins</li>
                  <li>La rédaction de cahiers des charges</li>
                  <li>
                    L’analyse de faisabilité <br />
                    (dossier d'étude, POC)
                  </li>
                  <li>La définition de solutions techniques</li>
                  <li>
                    L'audit d'éco-conception et l'optimisation de vos
                    applications, mesurable par l'intermédiaire d'indicateurs
                    multi-critères.
                  </li>
                  <li>Le chiffrage de vos projets et leur mise en œuvre</li>
                </ul>
                <span className={"highlight-block"}>
                  Le tout en intégrant les impacts économiques, sociaux et
                  environnementaux.
                </span>
                <span className={"highlight-block"}>
                  Tous nos clients sont parties prenantes du succès du projet
                  car nos méthodes agiles sont contributives et participatives.
                </span>
              </div>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/approche_condensed.svg"}
                alt={
                  "Groupe de personne dans un atelier de travail pour concevoir une application web"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise"}>
            <div className={"description"}>
              <div className={"number"}>02</div>
              <h1>Graphisme</h1>
              <p>
                Nous intervenons sur l'ensemble des fonctions nécessaires à la
                production visuelle de vos applications. <br />
                De l'<span className={"highlight"}>
                  expérience utilisateur
                </span>{" "}
                (UX Design) à l'<span className={"highlight"}>esthétique</span>{" "}
                (UI Design) de vos applications et à la mise à disposition de{" "}
                <span className={"highlight"}>prototypes</span> de votre
                produit.
              </p>
              <span className={"highlight-block"}>
                Notre objectif est de répondre uniquement à votre besoin, sans
                superflu.
              </span>
              <span className={"highlight-block"}>
                Trouver le bon rapport entre esthétisme et ergonomie.
              </span>
            </div>
            <div className={"image"}>
              <StaticImage
                src={"../images/ux_ui_design.png"}
                alt={
                  "Personne dans une réflexion UX-UI design pour une application"
                }
                placeholder={"none"}
                quality={90}
                width={600}
              />
            </div>
          </div>

          <div className={"bloc-expertise special-case"}>
            <div className={"description-expertises"}>
              <div className={"number"}>03</div>
              <h1>Applicatif</h1>
              <p className={"justify"}>
                Nous <span>intervenons</span> sur tous les métiers d'études et
                développement, de la conception au déploiement, en passant par
                le pilotage MOE, le développement logiciel, la recette et la
                maintenance applicative.
              </p>
              <span className={"highlight-block"}>
                Nous maitrisons les différentes technologies utiles au
                développement.
              </span>
              <span className={"highlight-block"}>
                Nous adoptons une démarche de facilitateur, en gardant le focus
                sur le résultat.
              </span>
              <div className={"image"}>
                <StaticImage
                  src={"../images/expertises_condensed.svg"}
                  alt={"Développeur concevant une application"}
                  placeholder={"none"}
                  quality={90}
                  width={600}
                />
              </div>
            </div>
            <div className={"expertises-content"}>
              <ul>
                <li>
                  <span className={"highlight"}>WEB</span>
                  Création/refonte d’un site média à fort trafic, d’une
                  interface d’administration, d’un site corporate (Vitrine),
                  éditorial (CMS), e-commerce ou d'une plateforme SaaS...
                </li>
                <li>
                  <span className={"highlight"}>MOBILE</span>
                  La mobilité est présente partout : Une application mobile
                  permet à un produit de proposer de nouvelles expériences
                  d’utilisation de ses services.
                </li>
                <li>
                  <span className={"highlight"}>METIER</span>
                  Applications <span>sur-mesures</span>,{" "}
                  <span>éco-conçues</span> et <span>accessibles</span> partout
                  dans le monde. <br />
                  Elles sont <span>adaptées à vos besoins</span>, sans
                  fonctionnalités superflues.
                </li>
                <li>
                  <span className={"highlight"}>API</span>
                  Nous privilégions la création de nos plateformes sous la forme
                  d’API. Derrière cet acronyme se cache un outil permettant de
                  partager vos données dans un format standardisé.
                </li>
                <li className={"fullwidth"}>
                  <span className={"highlight"}>DEVOPS</span>
                  Nos équipes mettent en œuvre l’<span>automatisation</span> des
                  tâches et le <span>déploiement continu</span> de vos projets
                  dans le but de rendre vos infrastructures{" "}
                  <span>élastiques</span>, <span>maintenables</span> et{" "}
                  <span>sécurisées</span>.
                </li>
                {/*<li className={"fullwidth"}>*/}
                {/*  <span className={"highlight"}>MAINTENANCE & EVOLUTION</span>*/}
                {/*  Nous pouvons mettre en place une maintenance (correction de*/}
                {/*  bugs), programmer de nouveaux cycles de développements*/}
                {/*  (nouvelles fonctionnalités).*/}
                {/*</li>*/}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </MainLayout>
  );
}
